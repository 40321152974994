body {
  overscroll-behavior: none;
  margin: 0;
}

* {
  -webkit-user-select: none;
  user-select: none;
  box-shadow: none;
  touch-action: none;
  -webkit-tap-highlight-color: #0000;
  -webkit-text-size-adjust: none;
  outline: none;
}

html {
  -webkit-text-size-adjust: none;
}

body {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
}

#canvas {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  min-height: 100vh;
  margin: auto;
}

#canvas #myProgress {
  z-index: 1;
  box-sizing: border-box;
  border: 4px solid #1d3544;
  border-radius: 5px;
  width: calc(100% - 20px);
  height: 40px;
  position: absolute;
  left: 10px;
  overflow: hidden;
}

#canvas #myBarBackground {
  z-index: -2;
  box-sizing: border-box;
  background-color: #ed8b00;
  width: 100%;
  height: 100%;
  position: absolute;
}

#canvas #myBar {
  z-index: -1;
  background-color: #ed8b00;
  width: 0%;
  height: 100%;
  transition: width .5s linear;
  position: absolute;
  overflow: hidden;
}

#canvas #myBar.fastBar {
  transition: width 75ms linear;
}

#canvas #myBar.emptyBar {
  transition: width 10ms linear;
}

#canvas #myBar.refillBar {
  transition: width .1s linear;
}

#canvas #myBar.disabled {
  transition: none;
}

#canvas #score {
  z-index: 2;
  width: 100%;
  position: absolute;
  left: 0;
}

#canvas .cardsContainer {
  pointer-events: none;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  position: absolute;
}

#canvas .cardsContainer > div {
  flex-flow: row;
  justify-content: center;
  display: flex;
}

#canvas .cardsContainer ::selection {
  background: none;
}

#canvas .cardsContainer .container {
  perspective: 800px;
  pointer-events: all;
  cursor: pointer;
  -webkit-perspective: 1000px;
  -webkit-backface-visibility: hidden;
  width: 60px;
  height: 60px;
  margin: 4px;
  position: relative;
}

#canvas .cardsContainer .container .card {
  transform-style: preserve-3d;
  width: 100%;
  height: 100%;
  transition: transform .5s;
}

@keyframes match {
  50% {
    transform: scale(1.1)rotateY(180deg);
  }

  100% {
    transform: scale(1)rotateY(180deg);
  }
}

#canvas .cardsContainer .container .card.flipped {
  transform: rotateY(180deg);
}

#canvas .cardsContainer .container .card.flipped.match {
  animation: .25s ease-in-out .5s match;
}

#canvas .cardsContainer .container .card .front {
  backface-visibility: hidden;
  box-sizing: border-box;
  pointer-events: none;
  object-fit: fill;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  overflow: hidden;
}

#canvas .cardsContainer .container .card .front .image {
  pointer-events: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: block;
}

#canvas .cardsContainer .container .card .back {
  box-sizing: border-box;
  pointer-events: none;
  object-fit: contain;
  backface-visibility: hidden;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
  border: 3px solid #000;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  overflow: hidden;
  transform: rotateY(180deg);
}

#canvas .cardsContainer .container .card .back .image {
  pointer-events: none;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  display: block;
}

.hidden {
  visibility: hidden;
  display: none !important;
}

/*# sourceMappingURL=index.daf6da62.css.map */
